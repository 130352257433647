import React, { FunctionComponent } from 'react';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { phoneInputRegistrationConfig } from '../../helpers/form-validation-helper/form-validation-helper';
import { generateDataSelector } from '../../helpers/general-helper/general-helper';
import { formatPhoneNumber } from '../../utils/string/phone-number.utils';
import { Label } from '../common-components/label/label.component';
import { TextInput } from '../inputs/input/input.component';
import { MaskSensitiveContentWrapper } from '../mask-session-replay-content/mask-sensitive-content-wrapper.component';

export const PHONE_DESCRIPTION =
	'Phone numbers are only used to contact you in regards to issues with your order or shipment delivery purposes.';

const INPUT_LABEL = 'Phone Number';
const INPUT_ID = 'phoneNumber';

export type PhoneNumberInputProps = {
	control: Control<any>;
	errors: FieldErrors<FieldValues>;
	showDescription?: boolean;
	hideLabel?: boolean;
	onPhoneChanged?: (phoneNumber: string) => void;
	defaultValue?: string;
	automationHook?: string;
	label?: string;
	required?: boolean;
	hideAsterisk?: boolean;
	testId?: string;
	name?: string;
	formName: string;
	hideErrors?: boolean;
};

export const PhoneNumberInput: FunctionComponent<PhoneNumberInputProps> = ({
	control,
	errors,
	showDescription,
	hideLabel,
	onPhoneChanged,
	defaultValue,
	automationHook,
	label = INPUT_LABEL,
	required = false,
	testId,
	name,
	hideAsterisk = false,
	formName,
	hideErrors
}) => {
	const isolatedInputId = `${INPUT_ID}-${formName}`;
	return (
		<>
			{!hideLabel && <Label label={label} id={isolatedInputId} required={required && !hideAsterisk} />}
			<Controller
				name={name || INPUT_ID}
				control={control}
				defaultValue={defaultValue || ''}
				rules={{
					...phoneInputRegistrationConfig,
					required: required ? 'Please enter a phone number.' : false
				}}
				render={({ field: { onChange, onBlur, value } }) => (
					<MaskSensitiveContentWrapper>
						<TextInput
							id={isolatedInputId}
							name={INPUT_ID}
							ariaLabel={label}
							invalid={hideErrors ? false : Boolean(errors[name || INPUT_ID])}
							invalidMessage={errors[name || INPUT_ID]?.message}
							placeholder="(555) 555-5555"
							type={'tel'}
							onBlur={onBlur}
							onChange={(e) => {
								// This will format the phone number as the user types
								const phone = formatPhoneNumber(e.target.value);
								onChange(phone);
								if (onPhoneChanged) {
									onPhoneChanged(phone);
								}
							}}
							value={formatPhoneNumber(value)}
							description={showDescription ? PHONE_DESCRIPTION : undefined}
							data-automation={generateDataSelector('input', automationHook)}
							testId={testId}
						/>
					</MaskSensitiveContentWrapper>
				)}
			/>
		</>
	);
};
