import React, { FunctionComponent, useEffect } from 'react';
import { SHOWROOM_BOOK_APPT_IFRAME_LINK } from '../../../constants/links';
import { isShowroomBookApptEvent } from '../../../helpers/showroom/showroom.helper';
import { ShowroomBookApptEvent } from '../../../types/showroom.types';

export type ShowroomBookApptIframeProps = {
	branchPageUrl: string;
	onEvent: (event: ShowroomBookApptEvent) => void;
};

/**
 * This component loads the {@link ShowroomBookApptIframePage} in an iframe to isolate that page from the live-chat code,
 * because the Salesfoce chat system can't load in the same window as the Salesforce appointment booking code. This is due
 * to how salesforce components have to be in the same app configuration, but chat and appt booking are implemented in
 * separate configurations in Salesforce.
 */
export const ShowroomBookApptIframe: FunctionComponent<ShowroomBookApptIframeProps> = ({ branchPageUrl, onEvent }) => {
	// Listen for messages (e.g. cancel, finish) from the iframe and call the given `onEvent` handler.
	useEffect(() => {
		const handleMessage = (e: MessageEvent) => {
			if (e.origin !== window.location.origin) {
				return;
			}

			if (isShowroomBookApptEvent(e.data)) {
				onEvent(e.data);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, []);

	return (
		<iframe
			title="Book Showroom Appointment"
			id="showroom-book-appt-iframe"
			data-testid="showroom-book-appt-iframe"
			src={`${SHOWROOM_BOOK_APPT_IFRAME_LINK}?branchPageUrl=${encodeURIComponent(branchPageUrl)}`}
			className="h-100 w-100 bw0 overflow-y-scroll"
		/>
	);
};
