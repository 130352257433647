import { VideoFieldsFragment } from '../../__generated__/graphql-client-types';

export const FERGUSON_BATH_KITCHEN_LIGHTING_GALLERY_VIDEO: VideoFieldsFragment = {
	streamProviderCode: 1,
	title: 'Ferguson Bath Kitchen & Lighting Gallery',
	description: 'Ferguson Bath Kitchen & Lighting Gallery',
	id: '0',
	hashKey: '2f96vynljh',
	screenshotId: 0
};
