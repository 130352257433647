import React, { type FunctionComponent, type MouseEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { type CreateAccountInput } from '../../../__generated__/graphql-client-types';
import { generateCustomGTMEvent } from '../../../helpers/analytics/analytics-event.util';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { PRO_SIGNUP_STEPS } from '../../../helpers/analytics/gtm/pro-signup';
import {
	emailInputRegistrationConfig,
	genericRequiredFieldRegistrationConfig,
	newPasswordInputRegistrationConfig
} from '../../../helpers/form-validation-helper/form-validation-helper';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useCreateAccount } from '../../../hooks/apollo/authentication/authentication.hooks';
import { useSiteViewPreference } from '../../../hooks/apollo/employee/employee.hooks';
import { useHandleMutationResult } from '../../../hooks/general/handle-mutation-result.hooks';
import { useRecaptcha } from '../../../hooks/recaptcha/recaptcha.hooks';
import { TextButton } from '../../buttons';
import { SubmitButton } from '../../buttons/submit-button/submit-button.component';
import { GreyBox } from '../../common-components/grey-box/grey-box.component';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Recaptcha } from '../../common-components/recaptcha/recaptcha.component';
import { PasswordInput, TextInputWithLabel } from '../../inputs';
import { Checkbox } from '../../inputs/checkbox/checkbox.component';
import { PhoneNumberInput } from '../../phone-number-input/phone-number-input.component';
import { ProBadgeSvg } from '../../svg/general.component';
import { proBadgeIcon } from './create-account-form.css';

export type CreateAccountFormProps = {
	onCancel?: (e: MouseEvent<HTMLElement>) => void;
	onSuccess: (isProAccount: boolean) => void;
};

type FormInputs = {
	firstName: string;
	lastName: string;
	password: string;
	email: string;
	phone: string;
	signUpPro: boolean;
	emailSubscribe: boolean;
	internalNoPassword: boolean;
};

export const CreateAccountForm: FunctionComponent<CreateAccountFormProps> = ({ onCancel, onSuccess }) => {
	const { showAsEmployee } = useSiteViewPreference();
	const {
		register,
		handleSubmit,
		reset,
		watch,
		control,
		formState: { errors }
	} = useForm<FormInputs>({
		mode: 'onBlur'
	});
	const createAccount = useCreateAccount();
	const { handleException, handleResult } = useHandleMutationResult();
	const trackEvent = useTrackEvent();
	const { getToken } = useRecaptcha('signup');

	const needsPassword = !watch('internalNoPassword');

	const onSubmit = async (formInput: FormInputs) => {
		const token = await getToken();
		const input: CreateAccountInput = {
			firstName: formInput.firstName,
			lastName: formInput.lastName,
			email: formInput.email,
			phone: formInput.phone,
			password: formInput.internalNoPassword ? undefined : formInput.password,
			isProAccount: Boolean(formInput.signUpPro),
			subscribeToNewsletter: Boolean(formInput.emailSubscribe),
			token
		};

		createAccount(input)
			.then((result) =>
				handleResult(result, {
					onSuccess: () => {
						if (input.isProAccount) {
							trackEvent(generateCustomGTMEvent(TrackedEvent.PRO_SIGNUP_STEP, { stepNumber: PRO_SIGNUP_STEPS.STEP_ONE }));
						}
						onSuccess(input.isProAccount);
					}
				})
			)
			.catch(handleException);
	};

	useEffect(() => {
		// since our first render pass might have showAsEmployee as false, need to use reset to set default values
		reset({ internalNoPassword: showAsEmployee, emailSubscribe: true });
	}, [showAsEmployee]);
	return (
		<div>
			<h3>Create an Account</h3>
			<p>
				Creating an account lets you easily track and return purchases, save time with expedited checkout, create lists of favorite
				products, keep track of your projects, and more.
			</p>
			<form onSubmit={handleSubmit(onSubmit)} data-testid="create-account-form" aria-label="create new account form">
				<TextInputWithLabel
					className="b pb4"
					label="Email Address"
					required={true}
					invalid={Boolean(errors.email)}
					invalidMessage={errors.email?.message}
					{...register('email', emailInputRegistrationConfig)}
					automationHook="create-email"
				/>
				<div className="flex flex-wrap">
					<TextInputWithLabel
						className="b pb4 w-100 w-50-ns"
						label="First Name"
						required={true}
						invalid={Boolean(errors.firstName)}
						invalidMessage={errors.firstName?.message}
						{...register('firstName', genericRequiredFieldRegistrationConfig)}
						automationHook="create-first-name"
					/>
					<TextInputWithLabel
						className="b pl4-ns pb4 w-100 w-50-ns"
						label="Last Name"
						required={true}
						invalid={Boolean(errors.lastName)}
						invalidMessage={errors.lastName?.message}
						{...register('lastName', genericRequiredFieldRegistrationConfig)}
						automationHook="create-last-name"
					/>
				</div>

				<PasswordInput
					className="b pb4"
					label="Password"
					description="Minimum of 10 characters"
					invalid={Boolean(errors.password)}
					invalidMessage={errors.password?.message}
					required={needsPassword}
					{...register('password', {
						...newPasswordInputRegistrationConfig,
						required: needsPassword ? newPasswordInputRegistrationConfig.required : undefined
					})}
					id="password"
					data-testid="password-input"
					automationHook="create-password"
					disabled={!needsPassword}
				/>
				<GreyBox className="ba b--theme-grey-light br2 pv2">
					<Checkbox
						id="create-account-sign-up-pro-checkbox"
						label={
							<div className="flex items-center ws-normal">
								<ProBadgeSvg className={`${proBadgeIcon} theme-pro`} />
								<span className="ml2">Sign up as a Trade Professional</span>
							</div>
						}
						{...register('signUpPro')}
						automationHook="pro-enrollment"
					/>
				</GreyBox>
				<GreyBox className="ba b--theme-grey-light br2 pv2 mt3">
					<strong>Email Exclusives</strong>
					<div className="mt1">
						<Checkbox
							id="create-account-email-subscribe-checkbox"
							label={
								<div className="ws-normal">
									Sign up for special deals, new product releases, and design inspiration delivered to your inbox.
									Unsubscribe at any time.
								</div>
							}
							{...register('emailSubscribe')}
						/>
					</div>
				</GreyBox>
				{showAsEmployee && (
					<PanelComponent headingContent="Internal Only" className="mt3">
						<>
							<PhoneNumberInput
								name="phone"
								label="Consumer Phone Number"
								testId="internal-phone-input"
								formName="create-account"
								automationHook="internal-consumer-phone-number"
								control={control}
								errors={errors}
							/>
							<div className="mt3">
								<Checkbox
									id="create-account-internal-no-password-checkbox"
									label="Create without password. A password reset email will be sent."
									{...register('internalNoPassword')}
									automationHook="skip-password"
								/>
							</div>
						</>
					</PanelComponent>
				)}
				<div className="mt3">
					<SubmitButton automationHook="create-account">Create Account</SubmitButton>
				</div>
				<Recaptcha />
			</form>
			<div className="mt5">
				<TextButton onClick={onCancel} underlineHover={true} automationHook="account-already-have-account">
					Already have an account?
				</TextButton>
			</div>
		</div>
	);
};
